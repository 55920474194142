import React from "react";
import './progress.css';

const Progress = () => {
    return (
        <section className="progress-section">
            <div className="container">
                <div className="info">
                    <div className="item">
                        <p>nilsen.gg</p>
                        <p>60%</p>
                    </div>
                    <div className="progress-line" data-percent="60%">
                        <span style={{width: "60%"}}></span>
                    </div>
                </div>
                
            </div>
        </section>
    )
}

export default Progress;