import React from "react";
import Progress from "./components/progress";


function App() {
  return (
    <div className="App">
      <Progress></Progress>
    </div>
  );
}

export default App;
